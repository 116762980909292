import { useAppSelector } from 'hooks/reduxHooks';
import { Configuration } from 'modules/GlobalReducer';
import { getConfiguration } from 'modules/GlobalSelectors';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  conditionField: string;
  isToSatisfy: boolean;
}

const ConfiguredItem: React.FC<Props> = ({
  children,
  conditionField,
  isToSatisfy,
}) => {
  const {
    data: configuration,
    loading: isConfigurationLoading,
  }: Configuration = useAppSelector(getConfiguration);

  let condition = false;

  if (configuration) {
    condition = isToSatisfy
      ? configuration[conditionField]
      : !configuration[conditionField];
  }

  const isHidden = isConfigurationLoading || condition;

  if (isHidden) {
    return null;
  }

  return <>{children}</>;
};

export default ConfiguredItem;
