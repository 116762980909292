import * as R from 'ramda';
import {
  CreateNewChartArgs,
  DeleteVisualChartArgs,
  HistogramChartResponseWithType,
  HistogramInitialChartSettings,
  OnAddNewChartTabClickArgs,
  ScatterInitialChartSettings,
  SetActiveVisualizeHeaderTabArgs,
  UpdateChartSettingsArgs,
  onChartCreatingErrorPayload,
  onChartCreatingSuccessPayload,
  onDownloadChartClickArgs,
  onFetchChartRawDataErrorPayload,
  onFetchChartRawDataStartPayload,
  onFetchChartRawDataSuccessPayload,
  onRefetChartStartPayload,
  onRefetchChartErrorPayload,
  onRefetchDOEChartSuccessPayload,
  onRefetchHistogramChartSuccessPayload,
  onRefetchScatterChartSuccessPayload,
} from './VisualizeActions';

import { getFixedNumber } from 'utils/utils';
import { ApiModelName, ModelName } from '../../utils/enum';
import * as actionTypes from './VisualizeActionsTypes';
import { getDOEChartAxesDomains } from './VisualizeComponents/DOEChart/DOEChartUtils';
import { XAxisType } from './VisualizeComponents/DOEChart/model/DOEChart.interfaces';
import { DOEChartResponse } from './VisualizeComponents/DOEChart/model/DOEChartResponse';
import {
  HistogramChartAxesBasesType,
  HistogramChartTypeEnum,
} from './VisualizeComponents/HistogramChart/models/histogramChartModels';
import {
  CenterLineOptions,
  SizingOptions,
} from './VisualizeComponents/ScatterChart/ScatterSettings';
import { ScatterChartAxesBasesType } from './VisualizeComponents/ScatterChart/models/scatterChartModels';
import { ScatterChartResponse } from './VisualizeComponents/ScatterChart/models/scatterChartResponseModel';
import { getScatterColorGroups } from './VisualizeComponents/ScatterChart/scatterChartUtils';
import { calculateControlChartData } from './VisualizeComponents/ScatterChart/utils';
import { checkCharDataEqualRawData, getChartSettings } from './utils';

export const STATE_KEY = 'visualize';

export enum ChartTypeEnum {
  Scatter = 'Scatter',
  DOE = 'DOE',
  Histogram = 'Histogram',
}

export enum VisualizePanelsTypeEnum {
  LeftPanel = 'leftPanel',
  RightPanel = 'rightPanel',
}

export type ChartRawDataInfoType = {
  rawData: Record<string, any>[];
  loading: boolean;
  error: string;
  isCompleted: boolean;
};

export type ScatterSettingsType = {
  // charts
  xAxis: any;
  yAxis: any;
  centerLine: CenterLineOptions;
  proportionalSpacing: boolean;
  showControlLimits: boolean;
  highlightOutliers: boolean;
  highlightTrends: boolean;

  //points
  groupBy: any;
  colorBy: any;
  showLegend: boolean;
  labelBy: any;
  labelByChoices: any;
  showLabels: boolean;

  //axes
  xMinAxis: Date;
  xMaxAxis: Date;
  yMinAxis: number;
  yMaxAxis: number;

  //layout
  chartTitle: string;
  showTitle: boolean;
  sizingType: SizingOptions;
  chartWidth: number;
  chartHeight: number;
};

export type HistogramSettingsType = {
  // charts
  column: any;
  showBoxAndWhiskers: boolean;
  showMeanDiamonds: boolean;
  logarithmic: boolean;

  //points
  colorBy: any;
  labelBy: any;
  labelByChoices: any;
  showLabels: boolean;

  //axes
  minAxis: number;
  maxAxis: number;
  binSize: number;

  //layout
  chartTitle: string;
  showTitle: boolean;
  sizingType: SizingOptions;
  chartWidth: number;
  chartHeight: number;

  histogramChartType: HistogramChartTypeEnum;
};

export type DOESettingsType = {
  xAxis: XAxisType[];
  centerLineAll: CenterLineOptions;
  centerLineGroups: CenterLineOptions;
  showGroupLegend: boolean;
  showMeanDiamonds: boolean;
  pooledVariance: boolean;

  //points
  colorBy: any;
  showLegend: boolean;
  labelBy: any;
  labelByChoices: any;
  showLabels: boolean;
  jitter: number;

  //axes
  xMinAxis: number;
  xMaxAxis: number;
  yMinAxis: number;
  yMaxAxis: number;

  //layout
  chartTitle: string;
  showTitle: boolean;
  sizingType: SizingOptions;
  chartWidth: number;
  chartHeight: number;
};

export type ChartInfoType<S = any, D = any> = {
  chartId: string;
  title: string;
  type: ChartTypeEnum;
  settings: S;
  initialSettings: S;
  chartData: D;
  loading: boolean;
  error: string;
  hasInitialized: boolean;
};

export type ChartType = {
  chartsInfo: ChartInfoType[];
};

export type VisualizeDataType = {
  showSplitDisplayButton: boolean;
  isDisplaySplit: boolean;
  charts: {
    [VisualizePanelsTypeEnum.LeftPanel]: {
      showSelectChartType: boolean;
      chartsInfo: ChartInfoType[];
      activeTabId: string;
    };
    [VisualizePanelsTypeEnum.RightPanel]: {
      showSelectChartType: boolean;
      chartsInfo: ChartInfoType[];
      activeTabId: string;
    };
    chartsCount: number;
  };
  chartIdToDownload: string | null;
  chartRawDataInfo: ChartRawDataInfoType;
};

const initialModelNameState = {
  showSplitDisplayButton: false,
  isDisplaySplit: false,
  charts: {
    [VisualizePanelsTypeEnum.LeftPanel]: {
      chartsInfo: [],
      showSelectChartType: true,
      activeTabId: '',
    },
    [VisualizePanelsTypeEnum.RightPanel]: {
      chartsInfo: [],
      showSelectChartType: true,
      activeTabId: '',
    },
    chartsCount: 0,
  },
  chartIdToDownload: null,
  chartRawDataInfo: {
    rawData: [],
    loading: false,
    error: '',
    isCompleted: false,
  },
};

const initialState: Record<string, VisualizeDataType> = {
  [ModelName.Material]: initialModelNameState,
  [ModelName.Measurement]: initialModelNameState,
  [ModelName.Process]: initialModelNameState,
  [ModelName.MaterialType]: initialModelNameState,
  [ModelName.ControlType]: initialModelNameState,
  [ModelName.Experiment]: initialModelNameState,
  [ModelName.Program]: initialModelNameState,
  [ModelName.ProcessType]: initialModelNameState,
  [ModelName.MeasurementType]: initialModelNameState,
  [ModelName.Instrument]: initialModelNameState,
  [ModelName.Site]: initialModelNameState,
  [ModelName.Team]: initialModelNameState,
  [ModelName.User]: initialModelNameState,
  [ModelName.Cell]: initialModelNameState,
};

const ExploreReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  const { payload } = action;
  const modelName = payload?.modelName as ApiModelName;

  switch (action.type) {
    case actionTypes.SPLIT_VISUALIZE_DETAILS_DISPLAY: {
      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          isDisplaySplit: true,
          showSplitDisplayButton: false,
          charts: {
            [VisualizePanelsTypeEnum.RightPanel]: {
              showSelectChartType: true,
            },
          },
        },
      });
    }

    case actionTypes.ON_DOWNLOAD_CHART_CLICK: {
      const { chartId, modelName } = payload as onDownloadChartClickArgs;
      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          chartIdToDownload: chartId,
        },
      });
    }

    case actionTypes.ON_DOWNLOAD_CHART_FINISHED: {
      const { modelName } = payload;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          chartIdToDownload: null,
        },
      });
    }

    case actionTypes.CREATE_NEW_CHART: {
      const {
        panelType,
        chartType,
        chartId,
        modelName,
        axesBases,
        modelDefinitions,
      } = payload as CreateNewChartArgs<
        ScatterInitialChartSettings | HistogramInitialChartSettings,
        ScatterChartAxesBasesType | HistogramChartAxesBasesType
      >;

      const chartsCount = [
        ...state[modelName].charts.rightPanel.chartsInfo,
        ...state[modelName].charts.leftPanel.chartsInfo,
      ].filter((x) => x.type === chartType).length;

      const allChartsTitles = [
        ...state[modelName].charts.rightPanel.chartsInfo,
        ...state[modelName].charts.leftPanel.chartsInfo,
      ].map((t) => t.title);

      const chartSettings = getChartSettings({
        chartType,
        chartsCount: chartsCount,
        chartsTitles: allChartsTitles,
        axesBases,
        modelName,
        modelDefinitions,
      });

      const newChart = {
        chartId,
        title: chartSettings?.chartTitle,
        type: chartType,
        settings: chartSettings,
        initialSettings: chartSettings,
        loading: true,
        error: '',
      } as ChartInfoType;

      if (chartType === ChartTypeEnum.DOE) {
        // check if we have initialDots in one DOE chart, we can take them

        const initialDotsForDOECHart =
          [
            ...state[modelName].charts.rightPanel.chartsInfo,
            ...state[modelName].charts.leftPanel.chartsInfo,
          ]
            .filter((ch) => ch.type === ChartTypeEnum.DOE)
            .find((ch) => ch?.chartData?.dots?.length)?.chartData?.dots ?? [];

        if (initialDotsForDOECHart.length > 0) {
          newChart.hasInitialized = true;
          newChart.loading = false;
          newChart.error = '';
          newChart.chartData = {
            dots: initialDotsForDOECHart,
          };

          const { yMaxAxis, yMinAxis } = getDOEChartAxesDomains(
            initialDotsForDOECHart,
          );

          newChart.settings.yMinAxis = yMinAxis;
          newChart.settings.yMaxAxis = yMaxAxis;
        }
      }

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          showSplitDisplayButton: !state[modelName].isDisplaySplit,
          charts: {
            chartsCount: state[modelName].charts.chartsCount + 1,
            [panelType]: {
              chartsInfo: [
                ...state[modelName].charts[panelType].chartsInfo,
                newChart,
              ],
              activeTabId: newChart.chartId,
              showSelectChartType: false,
            },
          },
        },
      });
    }

    case actionTypes.UPDATE_CHART_SETTINGS: {
      const {
        panelType,
        chartId,
        updatedSettings,
        modelName,
        shouldResetDataSet,
      } = payload as UpdateChartSettingsArgs;

      const updatedChartsInfo = state[modelName].charts[
        panelType
      ].chartsInfo.map((c) => {
        if (c.chartId === chartId) {
          if (c.type == ChartTypeEnum.Scatter) {
            const chartDataDots =
              (shouldResetDataSet
                ? c.chartData?.['null']?.initialDots
                : c.chartData?.['null']?.dots) ?? [];

            updatedSettings.yMaxAxis = Number(updatedSettings.yMaxAxis);
            updatedSettings.yMinAxis = Number(updatedSettings.yMinAxis);

            const settings = { ...c.settings, ...updatedSettings };
            const calculatedScatterChartData = calculateControlChartData(
              chartDataDots,
              settings,
            );

            updatedSettings.yMaxAxis = Number(updatedSettings.yMaxAxis);
            updatedSettings.yMinAxis = Number(updatedSettings.yMinAxis);

            return {
              ...c,
              chartData: {
                null: {
                  ...calculatedScatterChartData,
                  initialDots: c.chartData?.['null']?.initialDots,
                },
              },
              title: updatedSettings.chartTitle,
              settings: { ...c.settings, ...updatedSettings },
            };
          }

          if (c.type == ChartTypeEnum.DOE) {
            const chartDataDots =
              (shouldResetDataSet
                ? c.chartData?.initialDots
                : c.chartData?.dots) ?? [];

            updatedSettings.yMaxAxis = Number(updatedSettings.yMaxAxis);
            updatedSettings.yMinAxis = Number(updatedSettings.yMinAxis);

            return {
              ...c,
              chartData: {
                dots: chartDataDots,
              },
              title: updatedSettings.chartTitle,
              settings: { ...c.settings, ...updatedSettings },
            };
          }

          return {
            ...c,
            title: updatedSettings.chartTitle,
            settings: { ...c.settings, ...updatedSettings },
          };
        } else {
          return c;
        }
      });

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              chartsInfo: updatedChartsInfo,
            },
          },
        },
      });
    }

    case actionTypes.ON_CREATE_NEW_CHART_ERROR: {
      const { panelType, chartId, error, modelName } =
        payload as onChartCreatingErrorPayload;

      const updatedChartsInfo = state[modelName].charts[
        panelType
      ].chartsInfo.map((c) => {
        if (c.chartId === chartId) {
          return {
            ...c,
            loading: false,
            error,
          };
        } else {
          return c;
        }
      });

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              chartsInfo: updatedChartsInfo,
            },
          },
        },
      });
    }

    case actionTypes.ON_CREATE_NEW_CHART_SUCCESS: {
      const chartType = payload.chartType;

      const isScatterChart = chartType === ChartTypeEnum.Scatter;
      const isHistogramChart = chartType === ChartTypeEnum.Histogram;
      const isDOEChart = chartType === ChartTypeEnum.DOE;

      const currentChartRawData = state[modelName].chartRawDataInfo;

      if (isScatterChart) {
        const { panelType, chartId, chartData, axesBases, modelName } =
          payload as onChartCreatingSuccessPayload<ScatterChartResponse>;
        const nullChart = chartData['null'];

        const dbIdsArray = nullChart.dots.map((x) => ({
          db_id: x.meta['id'],
        }));

        const isCharDataEqualRawData = checkCharDataEqualRawData(
          dbIdsArray,
          currentChartRawData.rawData,
        );

        const shouldUpdateChartRawData =
          isCharDataEqualRawData.chartDataHasFullKeys &&
          !isCharDataEqualRawData.areValuesEquals;

        const updatedChartsInfo = state[modelName].charts[
          panelType
        ].chartsInfo.map((c) => {
          if (c.chartId === chartId) {
            let settings = c.settings;
            const isScatterChart = c.type === ChartTypeEnum.Scatter;
            const colorByGroups = getScatterColorGroups(nullChart);

            if (isScatterChart) {
              settings = {
                ...settings,
                xMinAxis: new Date(nullChart.domain.x[0]),
                xMaxAxis: new Date(nullChart.domain.x[1]),
                yMinAxis: +nullChart.domain.y[0].toFixed(3),
                yMaxAxis: +nullChart.domain.y[1].toFixed(3),
                colorBy: colorByGroups[0],
                xAxis: axesBases?.xAxis[0],
                yAxis: axesBases?.yAxis[0],
              } as ScatterSettingsType;
            }

            return {
              ...c,
              loading: false,
              error: '',
              chartData: {
                ...chartData,
                null: {
                  ...chartData['null'],
                  initialDots: chartData['null']?.dots ?? [],
                },
              },
              settings,
              initialSettings: settings,
              hasInitialized: true,
            };
          } else {
            return c;
          }
        });

        return R.mergeDeepRight(state, {
          [modelName]: {
            ...state[modelName],
            ...(shouldUpdateChartRawData
              ? {
                  chartRawDataInfo: {
                    rawData: dbIdsArray,
                    loading: false,
                    isCompleted: false,
                    error: '',
                  },
                }
              : {}),
            charts: {
              [panelType]: {
                chartsInfo: updatedChartsInfo,
              },
            },
          },
        });
      }

      if (isHistogramChart) {
        const { panelType, chartId, modelName, chartData } =
          payload as onChartCreatingSuccessPayload<HistogramChartResponseWithType>;

        const dbIdsArray = chartData.dots.map((x) => ({
          db_id: x.meta['id'],
        }));

        const isCharDataEqualRawData = checkCharDataEqualRawData(
          dbIdsArray,
          currentChartRawData.rawData,
        );

        const shouldUpdateChartRawData =
          isCharDataEqualRawData.chartDataHasFullKeys &&
          !isCharDataEqualRawData.areValuesEquals;

        const isContiniousChartType =
          chartData.histogramChartType === HistogramChartTypeEnum.CONTINIOUS;

        const updatedChartsInfo = state[modelName].charts[
          panelType
        ].chartsInfo.map((c) => {
          if (c.chartId === chartId) {
            let settings: HistogramSettingsType = c.settings;

            if (isContiniousChartType) {
              const minAxis = chartData.dots.reduce(
                (acc, val) => Math.min(acc, val.y),
                chartData.dots[0].y,
              );

              const maxAxis = chartData.dots.reduce(
                (acc, val) => Math.max(acc, val.y),
                chartData.dots[0].y,
              );

              const binSize =
                maxAxis === minAxis ? minAxis : (maxAxis - minAxis) / 10;

              settings = {
                ...settings,
                binSize: getFixedNumber(binSize, 3),
                minAxis: getFixedNumber(minAxis, 3),
                maxAxis: getFixedNumber(maxAxis, 3),
              };
            }

            settings = {
              ...settings,
            } as HistogramSettingsType;

            return {
              ...c,
              loading: false,
              error: '',
              chartData,
              settings: {
                ...c.settings,
                ...settings,
                histogramChartType: chartData.histogramChartType,
              },
              initialSettings: settings,
              hasInitialized: true,
            };
          } else {
            return c;
          }
        });

        return R.mergeDeepRight(state, {
          [modelName]: {
            ...state[modelName],
            ...(shouldUpdateChartRawData
              ? {
                  chartRawDataInfo: {
                    rawData: dbIdsArray,
                    loading: false,
                    isCompleted: false,
                    error: '',
                  },
                }
              : {}),
            charts: {
              [panelType]: {
                chartsInfo: updatedChartsInfo,
              },
            },
          },
        });
      }

      if (isDOEChart) {
        const { panelType, chartId, modelName, chartData } =
          payload as onChartCreatingSuccessPayload<DOEChartResponse>;

        const updatedChartsInfo = state[modelName].charts[
          panelType
        ].chartsInfo.map((c) => {
          if (c.chartId === chartId) {
            const shouldUpdateDomainsSettings = !c.chartData?.dots;

            if (shouldUpdateDomainsSettings) {
              const { yMaxAxis, yMinAxis } = getDOEChartAxesDomains(
                chartData.dots.filter((x) => x.value !== null),
              );

              c.settings.yMinAxis = yMinAxis;
              c.settings.yMaxAxis = yMaxAxis;
            }

            return {
              ...c,
              loading: false,
              error: '',
              chartData: {
                dots: chartData.dots.filter((x) => x.value !== null),
              },
              settings: c.settings,
              initialSettings: c.settings,
              hasInitialized: true,
            };
          } else {
            return c;
          }
        });

        return R.mergeDeepRight(state, {
          [modelName]: {
            ...state[modelName],
            charts: {
              [panelType]: {
                chartsInfo: updatedChartsInfo,
              },
            },
            chartRawDataInfo: {
              rawData: chartData.dots,
              loading: false,
              isCompleted: true,
              error: '',
            },
          },
        });
      }
    }

    case actionTypes.SET_ACTIVE_VISUALIZE_HEADER_TAB: {
      const { panelType, activeTabId, modelName } =
        payload as SetActiveVisualizeHeaderTabArgs;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              activeTabId,
              showSelectChartType: false,
            },
          },
        },
      });
    }

    case actionTypes.ON_ADD_NEW_CHART_TAB_CLICK: {
      const { panelType, modelName } = payload as OnAddNewChartTabClickArgs;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              showSelectChartType: true,
              activeTabId: '',
            },
          },
        },
      });
    }

    case actionTypes.DELETE_VISUALIZE_CHART: {
      const { panelType, chartId, modelName } =
        payload as DeleteVisualChartArgs;
      const isLeftPanelType = panelType === VisualizePanelsTypeEnum.LeftPanel;
      const updatedChartsCount = state[modelName].charts.chartsCount - 1;

      const currentPanelState = state[modelName].charts[panelType];
      const currentChartIndex = currentPanelState.chartsInfo
        .map((x) => x.chartId)
        .indexOf(chartId);

      const updatedChartsInfoArray = currentPanelState.chartsInfo.filter(
        (x) => x.chartId !== chartId,
      );

      const isDeletedChartWasActive =
        currentPanelState?.activeTabId === chartId;

      const newActiveChartTab =
        currentChartIndex === 0
          ? updatedChartsInfoArray[0]
          : updatedChartsInfoArray[currentChartIndex - 1];

      const isPanelChartsInfoArrayEmpty = updatedChartsInfoArray.length === 0;
      const shouldMoveRightPanelChartsToLeft =
        isPanelChartsInfoArrayEmpty &&
        isLeftPanelType &&
        updatedChartsCount > 0;

      const updatedCharts = shouldMoveRightPanelChartsToLeft
        ? {
            [VisualizePanelsTypeEnum.LeftPanel]: {
              chartsInfo: state[modelName].charts.rightPanel.chartsInfo,
              activeTabId: state[modelName].charts.rightPanel.activeTabId,
            },
            [VisualizePanelsTypeEnum.RightPanel]: {
              chartsInfo: [],
              activeTabId: '',
              showSelectChartType: true,
            },
            chartsCount: updatedChartsCount,
          }
        : {
            [panelType]: {
              chartsInfo: updatedChartsInfoArray,
              activeTabId: isDeletedChartWasActive
                ? newActiveChartTab?.chartId
                : currentPanelState.activeTabId,
            },
            chartsCount: updatedChartsCount,
          };

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          ...(updatedCharts.chartsCount === 0
            ? {
                chartRawDataInfo: {
                  rawData: [],
                  loading: false,
                  error: '',
                  isCompleted: false,
                },
              }
            : {}),
          charts: updatedCharts,
          showSplitDisplayButton:
            (!isPanelChartsInfoArrayEmpty && state[modelName].isDisplaySplit) ||
            updatedChartsCount === 0
              ? false
              : true,
          isDisplaySplit: isPanelChartsInfoArrayEmpty
            ? false
            : state[modelName].isDisplaySplit,
        },
      });
    }

    case actionTypes.ON_REFETCH_SCATTER_CHART_START: {
      const { chartId, panelType, modelName } =
        payload as onRefetChartStartPayload;
      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) =>
          ch.chartId === chartId
            ? {
                ...ch,
                loading: true,
                error: '',
              }
            : ch,
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_SCATTER_CHART_SUCCESS: {
      const { chartId, panelType, chartData, modelName } =
        payload as onRefetchScatterChartSuccessPayload;
      const nullChart = chartData['null'];
      const colorByGroups = getScatterColorGroups(nullChart);
      const currentChartRawData = state[modelName].chartRawDataInfo;

      const dbIdsArray = nullChart.dots.map((x) => ({
        db_id: x.meta['id'],
      }));

      const isCharDataEqualRawData = checkCharDataEqualRawData(
        dbIdsArray,
        currentChartRawData.rawData,
      );

      const shouldUpdateChartRawData =
        isCharDataEqualRawData.chartDataHasFullKeys &&
        !isCharDataEqualRawData.areValuesEquals;

      const mainSettings = {
        xMinAxis: new Date(nullChart.domain.x[0]),
        xMaxAxis: new Date(nullChart.domain.x[1]),
        yMinAxis: +nullChart.domain.y[0].toFixed(3),
        yMaxAxis: +nullChart.domain.y[1].toFixed(3),
        colorBy: colorByGroups[0],
      };

      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) => {
          const isCurrentChart = ch.chartId === chartId;

          if (isCurrentChart) {
            const chartDataDots = chartData['null']?.dots ?? [];

            const settings = {
              ...ch.settings,
              centerLine:
                ch.settings.centerLine === CenterLineOptions.None
                  ? CenterLineOptions.None
                  : nullChart.center_mean
                    ? CenterLineOptions.Mean
                    : CenterLineOptions.Median,
            };

            const calculatedScatterChartData = calculateControlChartData(
              chartDataDots,
              settings,
            );

            return {
              ...ch,
              loading: false,
              error: '',
              chartData: {
                ...chartData,
                null: {
                  ...calculatedScatterChartData,
                  initialDots: chartDataDots,
                },
              },
              hasInitialized: true,
              settings: settings,
              initialSettings: {
                ...ch.initialSettings,
                ...mainSettings,
              },
            };
          } else {
            return ch;
          }
        },
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          ...(shouldUpdateChartRawData
            ? {
                chartRawDataInfo: {
                  rawData: dbIdsArray,
                  loading: false,
                  isCompleted: false,
                  error: '',
                },
              }
            : {}),
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_SCATTER_CHART_ERROR: {
      const { chartId, panelType, error, modelName } =
        payload as onRefetchChartErrorPayload;
      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) =>
          ch.chartId === chartId
            ? {
                ...ch,
                loading: false,
                error,
                chartData: null,
              }
            : ch,
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }

    case actionTypes.ON_REFETCH_HISTOGRAM_CHART_START: {
      const { chartId, panelType, modelName } =
        payload as onRefetChartStartPayload;
      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) =>
          ch.chartId === chartId
            ? {
                ...ch,
                loading: true,
                error: '',
              }
            : ch,
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_HISTOGRAM_CHART_SUCCESS: {
      const { chartId, panelType, histogramChartType, modelName } =
        payload as onRefetchHistogramChartSuccessPayload;

      let { chartData } = payload as onRefetchHistogramChartSuccessPayload;

      const currentChartRawData = state[modelName].chartRawDataInfo;

      const dbIdsArray = chartData.dots.map((x) => ({
        db_id: x.meta['id'],
      }));

      const isCharDataEqualRawData = checkCharDataEqualRawData(
        dbIdsArray,
        currentChartRawData.rawData,
      );

      const shouldUpdateChartRawData =
        isCharDataEqualRawData.chartDataHasFullKeys &&
        !isCharDataEqualRawData.areValuesEquals;

      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) => {
          const isMatchedChart = ch.chartId === chartId;

          if (isMatchedChart) {
            const isContiniousChartType =
              histogramChartType === HistogramChartTypeEnum.CONTINIOUS;

            let settings: HistogramSettingsType = ch.settings;

            if (isContiniousChartType) {
              chartData = {
                ...chartData,
                dots: chartData.dots.map((x) => ({
                  ...x,
                  y: typeof x.y === 'number' ? +x.y.toFixed(4) : x.y,
                })),
              };

              const minAxis = chartData.dots.reduce(
                (acc, val) => Math.min(acc, val.y),
                chartData.dots[0].y,
              );

              const maxAxis = chartData.dots.reduce(
                (acc, val) => Math.max(acc, val.y),
                chartData.dots[0].y,
              );

              const binSize =
                maxAxis === minAxis ? minAxis : (maxAxis - minAxis) / 10;

              settings = {
                ...ch.settings,
                binSize: getFixedNumber(binSize, 3),
                minAxis: getFixedNumber(minAxis, 3),
                maxAxis: getFixedNumber(maxAxis, 3),
              };
            }

            return {
              ...ch,
              loading: false,
              error: '',
              chartData,
              hasInitialized: true,
              settings: {
                ...settings,
                histogramChartType: histogramChartType,
              },
              initialSettings: {
                ...ch.initialSettings,
                binSize: settings.binSize,
                minAxis: settings.minAxis,
                maxAxis: settings.maxAxis,
                histogramChartType: histogramChartType,
              },
            };
          }
          return ch;
        },
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          ...(shouldUpdateChartRawData
            ? {
                chartRawDataInfo: {
                  rawData: dbIdsArray,
                  loading: false,
                  isCompleted: false,
                  error: '',
                },
              }
            : {}),
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_HISTOGRAM_CHART_ERROR: {
      const { chartId, panelType, error, modelName } =
        payload as onRefetchChartErrorPayload;
      const updatedCharts = state[modelName].charts[panelType].chartsInfo.map(
        (ch) =>
          ch.chartId === chartId
            ? {
                ...ch,
                loading: false,
                error,
                chartData: null,
              }
            : ch,
      );

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [panelType]: {
              ...state[modelName].charts[panelType],
              chartsInfo: updatedCharts,
            },
          },
        },
      });
    }

    case actionTypes.ON_REFETCH_DOE_CHART_START: {
      const { modelName } = payload as onRefetChartStartPayload;
      const updatedChartsLeftPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.LeftPanel
      ].chartsInfo.map((ch) => ({ ...ch, loading: true, error: '' }));
      const updatedChartsRightPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.RightPanel
      ].chartsInfo.map((ch) => ({ ...ch, loading: true, error: '' }));

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [VisualizePanelsTypeEnum.LeftPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.LeftPanel],
              chartsInfo: updatedChartsLeftPanel,
            },
            [VisualizePanelsTypeEnum.RightPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.RightPanel],
              chartsInfo: updatedChartsRightPanel,
            },
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_DOE_CHART_SUCCESS: {
      const { modelName, chartData } =
        payload as onRefetchDOEChartSuccessPayload;

      const updatedChartsLeftPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.LeftPanel
      ].chartsInfo.map((ch) => {
        const settings: DOESettingsType = ch.settings;

        const { yMaxAxis, yMinAxis } = getDOEChartAxesDomains(
          chartData.dots.filter((x) => x.value !== null),
        );

        settings.yMinAxis = yMinAxis;
        settings.yMaxAxis = yMaxAxis;

        ch.initialSettings.yMinAxis = yMinAxis;
        ch.initialSettings.yMaxAxis = yMaxAxis;

        return {
          ...ch,
          loading: false,
          error: '',
          chartData: {
            dots: chartData.dots.filter((x) => x.value !== null),
          },
          hasInitialized: true,
          settings: {
            ...settings,
          },
          initialSettings: {
            ...ch.initialSettings,
          },
        };
      });

      const updatedChartsRightPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.RightPanel
      ].chartsInfo.map((ch) => {
        const settings: DOESettingsType = ch.settings;

        const { yMaxAxis, yMinAxis } = getDOEChartAxesDomains(
          chartData.dots.filter((x) => x.value !== null),
        );

        settings.yMinAxis = yMinAxis;
        settings.yMaxAxis = yMaxAxis;

        ch.initialSettings.yMinAxis = yMinAxis;
        ch.initialSettings.yMaxAxis = yMaxAxis;

        return {
          ...ch,
          loading: false,
          error: '',
          chartData: {
            dots: chartData.dots.filter((x) => x.value !== null),
          },
          hasInitialized: true,
          settings: {
            ...settings,
          },
          initialSettings: {
            ...ch.initialSettings,
          },
        };
      });

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [VisualizePanelsTypeEnum.LeftPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.LeftPanel],
              chartsInfo: updatedChartsLeftPanel,
            },
            [VisualizePanelsTypeEnum.RightPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.RightPanel],
              chartsInfo: updatedChartsRightPanel,
            },
          },
          chartRawDataInfo: {
            rawData: chartData.dots,
            loading: false,
            error: '',
            isCompleted: true,
          },
        },
      });
    }
    case actionTypes.ON_REFETCH_DOE_CHART_ERROR: {
      const { error, modelName } = payload as onRefetchChartErrorPayload;

      const updatedChartsLeftPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.LeftPanel
      ].chartsInfo.map((ch) => ({
        ...ch,
        loading: false,
        error,
        chartData: null,
      }));
      const updatedChartsRightPanel = state[modelName].charts[
        VisualizePanelsTypeEnum.LeftPanel
      ].chartsInfo.map((ch) => ({
        ...ch,
        loading: false,
        error,
        chartData: null,
      }));

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          charts: {
            [VisualizePanelsTypeEnum.LeftPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.LeftPanel],
              chartsInfo: updatedChartsLeftPanel,
            },
            [VisualizePanelsTypeEnum.RightPanel]: {
              ...state[modelName].charts[VisualizePanelsTypeEnum.RightPanel],
              chartsInfo: updatedChartsRightPanel,
            },
          },
        },
      });
    }

    case actionTypes.ON_FETCH_CHART_RAW_DATA_START: {
      const { modelName } = payload as onFetchChartRawDataStartPayload;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          chartRawDataInfo: {
            rawData: [],
            loading: true,
            isCompleted: false,
            error: '',
          },
        },
      });
    }
    case actionTypes.ON_FETCH_CHART_RAW_DATA_SUCCESS: {
      const { modelName, rawData } =
        payload as onFetchChartRawDataSuccessPayload;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          chartRawDataInfo: {
            rawData,
            loading: false,
            error: '',
            isCompleted: true,
          },
        },
      });
    }
    case actionTypes.ON_FETCH_CHART_RAW_DATA_ERROR: {
      const { error, modelName } = payload as onFetchChartRawDataErrorPayload;

      return R.mergeDeepRight(state, {
        [modelName]: {
          ...state[modelName],
          chartRawDataInfo: {
            rawData: [],
            loading: false,
            error,
            isCompleted: false,
          },
        },
      });
    }

    default:
      return state;
  }
};

export default ExploreReducer;
