export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const SET_STACK_MODAL_DATA = 'SET_STACK_MODAL_DATA';
export const UPDATE_PREV_STACK_MODAL_DATA = 'UPDATE_PREV_STACK_MODAL_DATA';
export const CLEAR_STACK_MODAL_DATA = 'CLEAR_STACK_MODAL_DATA';
export const CLEAR_ALL_STACK_MODAL_DATA = 'CLEAR_ALL_STACK_MODAL_DATA';
export const FETCH_STACK_MODAL_DATA_START = 'FETCH_STACK_MODAL_DATA_START';
export const FETCH_STACK_MODAL_DATA_SUCCESS = 'FETCH_STACK_MODAL_DATA_SUCCESS';
export const FETCH_STACK_MODAL_DATA_ERROR = 'FETCH_STACK_MODAL_DATA_ERROR';
export const SET_GRID_API_TO_STACK_MODAL_DATA =
  'SET_GRID_API_TO_STACK_MODAL_DATA';

export const UPDATE_QUEUE_MODAL_STATE = 'UPDATE_QUEUE_MODAL_STATE';
export const CLEAR_QUEUE_MODAL_STATE = 'CLEAR_QUEUE_MODAL_STATE';

export const FETCH_FEEDBACK_DATA_START = 'FETCH_FEEDBACK_DATA_START';
export const FETCH_FEEDBACK_DATA_SUCCESS = 'FETCH_FEEDBACK_DATA_SUCCESS';
export const FETCH_FEEDBACK_DATA_ERROR = 'FETCH_FEEDBACK_DATA_ERROR';
export const CLEAR_FEEDBACK_DATA = 'CLEAR_FEEDBACK_DATA';

export const SET_MODAL_EVENT_DATA = 'SET_MODAL_EVENT_DATA';
export const CLEAR_MODAL_EVENT_DATA = 'CLEAR_MODAL_EVENT_DATA';

export const MODEL_DEFINITIONS_START = 'MODEL_DEFINITIONS_START';
export const MODEL_DEFINITIONS_SUCCESS = 'MODEL_DEFINITIONS_SUCCESS';
export const MODEL_DEFINITIONS_ERROR = 'MODEL_DEFINITIONS_ERROR';

export const CREATE_MODEL_ITEM_START = 'CREATE_MODEL_ITEM_START';
export const CREATE_MODEL_ITEM_SUCCESS = 'CREATE_MODEL_ITEM_SUCCESS';
export const CREATE_MODEL_ITEM_ERROR = 'CREATE_MODEL_ITEM_ERROR';

export const EDIT_MODEL_ITEM_START = 'EDIT_MODEL_ITEM_START';
export const EDIT_MODEL_ITEM_SUCCESS = 'EDIT_MODEL_ITEM_SUCCESS';
export const EDIT_MODEL_ITEM_ERROR = 'EDIT_MODEL_ITEM_ERROR';

export const EDIT_MODEL_MULTIPLE_ITEM_START = 'EDIT_MODEL_MULTIPLE_ITEM_START';
export const EDIT_MODEL_MULTIPLE_ITEM_SUCCESS =
  'EDIT_MODEL_MULTIPLE_ITEM_SUCCESS';
export const EDIT_MODEL_MULTIPLE_ITEM_ERROR = 'EDIT_MODEL_MULTIPLE_ITEM_ERROR';

export const CLEAR_STATE_MODEL_ITEM = 'CLEAR_STATE_MODEL_ITEM';

export const CANCEL_CHECK_START = 'CANCEL_CHECK_START';
export const CANCEL_CHECK_SUCCESS = 'CANCEL_CHECK_SUCCESS';
export const CANCEL_CHECK_ERROR = 'CANCEL_CHECK_ERROR';
export const CANCEL_MULTIPLE_START = 'CANCEL_MULTIPLE_START';
export const CANCEL_MULTIPLE_SUCCESS = 'CANCEL_MULTIPLE_SUCCESS';
export const CANCEL_MULTIPLE_ERROR = 'CANCEL_MULTIPLE_ERROR';
export const CLEAR_CANCEL_STATE = 'CLEAR_CANCEL_STATE';

export const REACTIVATE_MULTIPLE_START = 'REACTIVATE_MULTIPLE_START';
export const REACTIVATE_MULTIPLE_SUCCESS = 'REACTIVATE_MULTIPLE_SUCCESS';
export const REACTIVATE_MULTIPLE_ERROR = 'REACTIVATE_MULTIPLE_ERROR';
export const CLEAR_REACTIVATE_STATE = 'CLEAR_REACTIVATE_STATE';

export const FETCH_MPM_FILE_IDS_START = 'FETCH_MPM_FILE_IDS_START';
export const FETCH_MPM_FILE_IDS_SUCCESS = 'FETCH_MPM_FILE_IDS_SUCCESS';
export const FETCH_MPM_FILE_IDS_ERROR = 'FETCH_MPM_FILE_IDS_ERROR';
export const DOWNLOAD_ORIGINAL_FILES_FINISHED =
  'DOWNLOAD_ORIGINAL_FILES_FINISHED';
export const CLEAR_DOWNLOAD_ORIGINAL_FILE_STATE =
  'CLEAR_DOWNLOAD_ORIGINAL_FILE_STATE';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const SET_USERS_TABLE_FILTER = 'SET_USERS_TABLE_FILTER';
export const SET_USERS_TABLE_SORT = 'SET_USERS_TABLE_SORT';
export const SET_USERS_SHOW_CANCEL = 'SET_USERS_SHOW_CANCEL';
export const SET_USERS_SELECTED_NODES = 'SET_USERS_SELECTED_NODES';
export const CLEAR_USERS_TABLE = 'CLEAR_USERS_TABLE';

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';
export const SET_TEAMS_TABLE_FILTER = 'SET_TEAMS_TABLE_FILTER';
export const SET_TEAMS_TABLE_SORT = 'SET_TEAMS_TABLE_SORT';
export const SET_TEAMS_SHOW_CANCEL = 'SET_TEAMS_SHOW_CANCEL';
export const SET_TEAMS_SELECTED_NODES = 'SET_TEAMS_SELECTED_NODES';
export const CLEAR_TEAMS_TABLE = 'CLEAR_TEAMS_TABLE';

export const FETCH_SITES_START = 'FETCH_SITES_START';
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
export const FETCH_SITES_ERROR = 'FETCH_SITES_ERROR';
export const SET_SITES_TABLE_FILTER = 'SET_SITES_TABLE_FILTER';
export const SET_SITES_TABLE_SORT = 'SET_SITES_TABLE_SORT';
export const SET_SITES_SHOW_CANCEL = 'SET_SITES_SHOW_CANCEL';
export const SET_SITES_SELECTED_NODES = 'SET_SITES_SELECTED_NODES';
export const CLEAR_SITES_TABLE = 'CLEAR_SITES_TABLE';

export const FETCH_PROCESS_TYPES_START = 'FETCH_PROCESS_TYPES_START';
export const FETCH_PROCESS_TYPES_SUCCESS = 'FETCH_PROCESS_TYPES_SUCCESS';
export const FETCH_PROCESS_TYPES_ERROR = 'FETCH_PROCESS_TYPES_ERROR';
export const SET_PROCESS_TYPES_TABLE_FILTER = 'SET_PROCESS_TYPES_TABLE_FILTER';
export const SET_PROCESS_TYPES_TABLE_SORT = 'SET_PROCESS_TYPES_TABLE_SORT';
export const SET_PROCESS_TYPES_SHOW_CANCEL = 'SET_PROCESS_TYPES_SHOW_CANCEL';
export const SET_PROCESS_TYPES_SELECTED_NODES =
  'SET_PROCESS_TYPES_SELECTED_NODES';
export const CLEAR_PROCESS_TYPES_TABLE = 'CLEAR_PROCESS_TYPES_TABLE';

export const FETCH_PROCESSES_START = 'FETCH_PROCESSES_START';
export const FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS';
export const FETCH_PROCESSES_ERROR = 'FETCH_PROCESSES_ERROR';
export const SET_PROCESSES_TABLE_FILTER = 'SET_PROCESSES_TABLE_FILTER';
export const SET_PROCESSES_TABLE_SORT = 'SET_PROCESSES_TABLE_SORT';
export const SET_PROCESSES_SHOW_CANCEL = 'SET_PROCESSES_SHOW_CANCEL';
export const SET_PROCESSES_SELECTED_NODES = 'SET_PROCESSES_SELECTED_NODES';
export const CLEAR_PROCESSES_TABLE = 'CLEAR_PROCESSES_TABLE';

export const FETCH_MEASUREMENT_TYPES_START = 'FETCH_MEASUREMENT_TYPES_START';
export const FETCH_MEASUREMENT_TYPES_SUCCESS =
  'FETCH_MEASUREMENT_TYPES_SUCCESS';
export const FETCH_MEASUREMENT_TYPES_ERROR = 'FETCH_MEASUREMENT_TYPES_ERROR';
export const SET_MEASUREMENT_TYPES_TABLE_FILTER =
  'SET_MEASUREMENT_TYPES_TABLE_FILTER';
export const SET_MEASUREMENT_TYPES_TABLE_SORT =
  'SET_MEASUREMENT_TYPES_TABLE_SORT';
export const SET_MEASUREMENT_TYPES_SHOW_CANCEL =
  'SET_MEASUREMENT_TYPES_SHOW_CANCEL';
export const SET_MEASUREMENT_TYPES_SELECTED_NODES =
  'SET_MEASUREMENT_TYPES_SELECTED_NODES';
export const CLEAR_MEASUREMENT_TYPES_TABLE = 'CLEAR_MEASUREMENT_TYPES_TABLE';

export const FETCH_MEASUREMENTS_START = 'FETCH_MEASUREMENTS_START';
export const FETCH_MEASUREMENTS_SUCCESS = 'FETCH_MEASUREMENTS_SUCCESS';
export const FETCH_MEASUREMENTS_ERROR = 'FETCH_MEASUREMENTS_ERROR';
export const SET_MEASUREMENTS_TABLE_FILTER = 'SET_MEASUREMENTS_TABLE_FILTER';
export const SET_MEASUREMENTS_TABLE_SORT = 'SET_MEASUREMENTS_TABLE_SORT';
export const SET_MEASUREMENTS_SHOW_CANCEL = 'SET_MEASUREMENTS_SHOW_CANCEL';
export const SET_MEASUREMENTS_SELECTED_NODES =
  'SET_MEASUREMENTS_SELECTED_NODES';
export const CLEAR_MEASUREMENTS_TABLE = 'CLEAR_MEASUREMENTS_TABLE';

export const FETCH_MATERIAL_TYPES_START = 'FETCH_MATERIAL_TYPES_START';
export const FETCH_MATERIAL_TYPES_SUCCESS = 'FETCH_MATERIAL_TYPES_SUCCESS';
export const FETCH_MATERIAL_TYPES_ERROR = 'FETCH_MATERIAL_TYPES_ERROR';
export const SET_MATERIAL_TYPES_TABLE_FILTER =
  'SET_MATERIAL_TYPES_TABLE_FILTER';
export const SET_MATERIAL_TYPES_TABLE_SORT = 'SET_MATERIAL_TYPES_TABLE_SORT';
export const SET_MATERIAL_TYPES_SHOW_CANCEL = 'SET_MATERIAL_TYPES_SHOW_CANCEL';
export const SET_MATERIAL_TYPES_SELECTED_NODES =
  'SET_MATERIAL_TYPES_SELECTED_NODES';
export const CLEAR_MATERIAL_TYPES_TABLE = 'CLEAR_MATERIAL_TYPES_TABLE';

export const FETCH_MATERIALS_START = 'FETCH_MATERIALS_START';
export const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS';
export const FETCH_MATERIALS_ERROR = 'FETCH_MATERIALS_ERROR';
export const SET_MATERIALS_TABLE_FILTER = 'SET_MATERIALS_TABLE_FILTER';
export const SET_MATERIALS_TABLE_SORT = 'SET_MATERIALS_TABLE_SORT';
export const SET_MATERIALS_SHOW_CANCEL = 'SET_MATERIALS_SHOW_CANCEL';
export const SET_MATERIALS_SELECTED_NODES = 'SET_MATERIALS_SELECTED_NODES';
export const CLEAR_MATERIALS_TABLE = 'CLEAR_MATERIALS_TABLE';

export const FETCH_INSTRUMENTS_START = 'FETCH_INSTRUMENTS_START';
export const FETCH_INSTRUMENTS_SUCCESS = 'FETCH_INSTRUMENTS_SUCCESS';
export const FETCH_INSTRUMENTS_ERROR = 'FETCH_INSTRUMENTS_ERROR';
export const SET_INSTRUMENTS_TABLE_FILTER = 'SET_INSTRUMENTS_TABLE_FILTER';
export const SET_INSTRUMENTS_TABLE_SORT = 'SET_INSTRUMENTS_TABLE_SORT';
export const SET_INSTRUMENTS_SHOW_CANCEL = 'SET_INSTRUMENTS_SHOW_CANCEL';
export const SET_INSTRUMENTS_SELECTED_NODES = 'SET_INSTRUMENTS_SELECTED_NODES';
export const CLEAR_INSTRUMENTS_TABLE = 'CLEAR_INSTRUMENTS_TABLE';

export const FETCH_FILES_START = 'FETCH_FILES_START';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_ERROR = 'FETCH_FILES_ERROR';
export const SET_FILES_TABLE_FILTER = 'SET_FILES_TABLE_FILTER';
export const SET_FILES_TABLE_SORT = 'SET_FILES_TABLE_SORT';
export const SET_FILES_SHOW_CANCEL = 'SET_FILES_SHOW_CANCEL';
export const SET_FILES_SELECTED_NODES = 'SET_FILES_SELECTED_NODES';
export const CLEAR_FILES_TABLE = 'CLEAR_FILES_TABLE';

export const FETCH_BFD_START = 'FETCH_BFD_START';
export const FETCH_BFD_SUCCESS = 'FETCH_BFD_SUCCESS';
export const FETCH_BFD_ERROR = 'FETCH_BFD_ERROR';

export const FETCH_EXPERIMENTS_START = 'FETCH_EXPERIMENTS_START';
export const FETCH_EXPERIMENTS_SUCCESS = 'FETCH_EXPERIMENTS_SUCCESS';
export const FETCH_EXPERIMENTS_ERROR = 'FETCH_EXPERIMENTS_ERROR';
export const SET_EXPERIMENTS_TABLE_FILTER = 'SET_EXPERIMENTS_TABLE_FILTER';
export const SET_EXPERIMENTS_TABLE_SORT = 'SET_EXPERIMENTS_TABLE_SORT';
export const SET_EXPERIMENTS_SHOW_CANCEL = 'SET_EXPERIMENTS_SHOW_CANCEL';
export const SET_EXPERIMENTS_SELECTED_NODES = 'SET_EXPERIMENTS_SELECTED_NODES';
export const CLEAR_EXPERIMENTS_TABLE_FILTER = 'CLEAR_EXPERIMENTS_TABLE_FILTER';
export const SET_EXPERIMENTS_IS_FILTER_INITIALIZED =
  'SET_EXPERIMENTS_IS_FILTER_INITIALIZED';

export const FETCH_CONTROL_TYPES_START = 'FETCH_CONTROL_TYPES_START';
export const FETCH_CONTROL_TYPES_SUCCESS = 'FETCH_CONTROL_TYPES_SUCCESS';
export const FETCH_CONTROL_TYPES_ERROR = 'FETCH_CONTROL_TYPES_ERROR';
export const SET_CONTROL_TYPES_TABLE_FILTER = 'SET_CONTROL_TYPES_TABLE_FILTER';
export const SET_CONTROL_TYPES_TABLE_SORT = 'SET_CONTROL_TYPES_TABLE_SORT';
export const SET_CONTROL_TYPES_SHOW_CANCEL = 'SET_CONTROL_TYPES_SHOW_CANCEL';
export const SET_CONTROL_TYPES_SELECTED_NODES =
  'SET_CONTROL_TYPES_SELECTED_NODES';
export const CLEAR_CONTROL_TYPES_TABLE = 'CLEAR_CONTROL_TYPES_TABLE';

export const FETCH_CONTEXT_MENU_DATA_START = 'FETCH_CONTEXT_MENU_DATA_START';
export const FETCH_CONTEXT_MENU_DATA_SUCCESS =
  'FETCH_CONTEXT_MENU_DATA_SUCCESS';
export const FETCH_CONTEXT_MENU_DATA_ERROR = 'FETCH_CONTEXT_MENU_DATA_ERROR';
export const CLEAR_CONTEXT_MENU_DATA = 'CLEAR_CONTEXT_MENU_DATA';

export const SET_AG_GRID_API = 'SET_AG_GRID_API';

export const FETCH_USER_ORGANIZATIONS_START = 'FETCH_USER_ORGANIZATIONS_START';
export const FETCH_USER_ORGANIZATIONS_SUCCESS =
  'FETCH_USER_ORGANIZATIONS_SUCCESS';
export const FETCH_USER_ORGANIZATIONS_ERROR = 'FETCH_USER_ORGANIZATIONS_ERROR';
export const UPDATE_USER_SELECTED_ORGANIZATION =
  'UPDATE_USER_SELECTED_ORGANIZATION';
export const UPDATE_USER_ACTIVE_ORGANIZATION =
  'UPDATE_USER_ACTIVE_ORGANIZATION';
export const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';

export const FETCH_CONFIGURATION_START = 'FETCH_CONFIGURATION_START';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_ERROR = 'FETCH_CONFIGURATION_ERROR';

export const FETCH_CELLS_START = 'FETCH_CELLS_START';
export const FETCH_CELLS_SUCCESS = 'FETCH_CELLS_SUCCESS';
export const FETCH_CELLS_ERROR = 'FETCH_CELLS_ERROR';
export const SET_CELLS_TABLE_FILTER = 'SET_CELLS_TABLE_FILTER';
export const SET_CELLS_TABLE_SORT = 'SET_CELLS_TABLE_SORT';
export const SET_CELLS_SHOW_CANCEL = 'SET_CELLS_SHOW_CANCEL';
export const SET_CELLS_SELECTED_NODES = 'SET_CELLS_SELECTED_NODES';
export const CLEAR_CELLS_TABLE = 'CLEAR_CELLS_TABLE';
