import Icon from '@mdi/react';
import { mdiFileTableBoxOutline } from '@mdi/js';
import {
  Chat as ChatIcon,
  ChecklistRtl,
  EventNote,
  DescriptionOutlined as File,
  Grain as Material,
  ImageAspectRatio as Measurement,
  SwapCalls as Process,
  DomainOutlined as Site,
  PeopleOutlineOutlined as Team,
  PermIdentity as User,
} from '@mui/icons-material';
import Experiment from 'mdi-material-ui/Flask';
import {
  ControlTypesIcon as ControlType,
  InstrumentIcon as Instrument,
  MaterialTypesIcon as MaterialType,
  MeasurementTypesIcon as MeasurementType,
  ProcessTypesIcon as ProcessType,
} from '../Icons';

import colors from 'theme/patterns/colors';
import { ModelName } from 'utils/enum';

interface ModelIconProps {
  modelName: ModelName;
  color?: string;
  [x: string]: any;
}

const ModelIcon = ({ modelName, color, ...rest }: ModelIconProps) => {
  const iconsMap: Record<ModelName, React.ReactElement> = {
    material: <Material style={{ color: colors.material.color }} {...rest} />,
    process: (
      <Process
        style={{
          color: color || colors.process.color,
          transform: 'rotate(90deg)',
          ...rest.style,
        }}
      />
    ),
    measurement: (
      <Measurement style={{ color: colors.measurement.color }} {...rest} />
    ),
    experiment: <Experiment style={{ color: colors.primary }} {...rest} />,
    program: <File style={{ color: colors.primary }} {...rest} />,
    feedback: <ChatIcon style={{ color: colors.primary }} {...rest} />,
    materialType: <MaterialType color={color || colors.primary} {...rest} />,
    processType: <ProcessType color={color || colors.primary} {...rest} />,
    measurementType: (
      <MeasurementType color={color || colors.primary} {...rest} />
    ),
    controlType: <ControlType color={color || colors.primary} {...rest} />,
    user: <User style={{ color: colors.primary }} {...rest} />,
    team: <Team style={{ color: colors.primary }} {...rest} />,
    site: <Site style={{ color: colors.primary }} {...rest} />,
    instrument: <Instrument color={color || colors.primary} {...rest} />,
    version: <></>,
    branch: <></>,
    message: <EventNote style={{ color: colors.primary }} {...rest} />,
    directive: <ChecklistRtl style={{ color: colors.primary }} {...rest} />,
    organizationSettings: <></>,
    cell: <Icon path={mdiFileTableBoxOutline} size={1} />,
  };

  return iconsMap[modelName];
};

export default ModelIcon;
